import React from 'react'
import { Container } from 'reactstrap'

import Layout from '../../components/Layout'

import '../../templates/white-paper.scss'


const WhitePaperCMSPage = () => (
  <Layout
      section="cms-white-paper"
      title={`CMS Whitepaper`}
    >
    <Container className="py-5">
      <h1>White paper</h1>
      <h2>Embeds</h2>
      <p>To ensure responsive embeds.</p>
      <div>
        <h2>Video</h2>
        <h3>Youtube</h3>
        <p>Replace the url in the "src" attribute of the example code below with the embed url from youtube</p>
        <p><strong>NB: use the URL from the embed option e.g. https://www.youtube.com/embed/skG2-ObpQvA NOT the share link url e.g. https://youtu.be/skG2-ObpQvA i.e. look for "embed" in the url</strong></p>
        
        <h4>Example</h4>
        <p>This code</p>
        <code>
          <pre>
            &lt;div class="embed embed-responsive embed-responsive-16by9"&gt;
              &lt;iframe class="embed-responsive-item" width="560" height="315" src="https://www.youtube.com/embed/SMyafaMiejs" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen&gt;&lt;/iframe&gt;
            &lt;/div&gt;
          </pre>
        </code>
        <p>produces</p>
        <div className="embed embed-responsive embed-responsive-16by9">
          <iframe className="embed-responsive-item" width="560" height="315" src="https://www.youtube.com/embed/SMyafaMiejs" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
        </div>
        
      </div>
    </Container>
  </Layout>
)

export default WhitePaperCMSPage